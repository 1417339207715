<template>
    <div>
        <label class=" form-control-label">Phone Number(s)</label>
        <div class="form-group d-inline-block w-100" v-for="(phone, index) in client?.client_phones" :key="index">
            <div class="row">
                <div class="col-md-8 d-flex">
                    <div class="custom-control custom-radio mt-2 mr-1">
                        <input type="radio" :id="'phone_preferred_' + index" name="customRadio"
                            :checked="phone.preferred == 1" class="custom-control-input"
                            @change="setPreferredPhone(index)">
                        <label class="custom-control-label" :for="'phone_preferred_' + index"></label>
                    </div>

                    <input :id="'contact_number_' + index" class="au-input au-input--full form-control"
                        style="width :100%" :name="'contact_number_' + index" :disabled="!hasClientUpdatePermission"
                        type="text" v-model="phone.value"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="" />

                </div>
                <div class="col-md-4 text-end mt-2  ">

                    <i class="fa-solid fa-comment fa-xl mr-2" :class="phone.comment ? 'text-dark' : 'text-muted'"
                        data-toggle="tooltip" data-placement="top" :id="'phone_Popover' + index" v-b-popover.hover.left
                        @click="openPhoneModal(index)" :target="'phone_Popover' + index" style="cursor: pointer;"></i>
                    <b-popover variant="warning" :target="'phone_Popover' + index" triggers="hover" placement="left"
                        class=" " v-if="phone.comment">
                        <div class="iq-card-body p-0 ">
                            <ul style="list-style:disc;margin-bottom: 0px;margin-top: 0px;">
                                <li><span>{{ phone.comment }}</span></li>
                            </ul>
                        </div>
                    </b-popover>

                    <i v-if="client.client_phones.length == index + 1" class="fa-solid fa-plus fa-xl ml-2 text-primary"
                        variant="primary" type="button" value="Add Another Phone" @click="addNewPhone()"></i>


                    <i v-if="
                        client?.client_phones?.length > 1" class="fa-solid fa-xmark fa-xl ml-2 text-danger "
                        variant="danger" type="button" value="Remove"
                        @click="removeEmailAndPhone(phone.id, 'phone', index)"></i>

                </div>
            </div>
        </div>
        <transition name="modal">
            <div class="modal-mask" v-if="modal_phone_comment">
                <div class="modal modal-wrapper">
                    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div class="modal-content ">
                            <ValidationObserver ref="observer">
                                <form @submit.prevent="savePhoneComment">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Phone Comment</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" @click="closePhoneModal">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body hei pt-2">
                                        <div class="row">
                                            <textarea type="text" v-model="commentPhoneText" class="form-control"
                                                id="phoneComment_" placeholder="Enter comment for phone"
                                                ref="phoneCommentRefs" rows="4"></textarea>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn border-none btn-primary btn-sm ">Save</button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import store from "@/store/index.js";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.css";

export default {
    name: "phones",

    data() {
        return {
            client_phones: [{ "id": 0, "value": "", "comment": "" }],
            modal_phone_comment: false,
            commentPhoneText: '',
        }
    },

    props: {
        client: {
            type: Object,
            default: () => ({}),
        },
        getDetail: {
            type: Function,
            required: true,
        },
    },
    computed: {
        hasClientUpdatePermission() {
            const slugsList = this.$store.state.slugsList;
            const desiredData = 'client-update-contacts';
            let hasPermission = slugsList.includes(desiredData);
            return hasPermission;
        },
    },

    methods: {
        openPhoneModal(index) {
            this.modal_phone_comment = true;
            this.commentPhoneText = this.client.client_phones[index].comment ? this.client.client_phones[index].comment : '';
            this.selectedPhoneId = index;
        },
        savePhoneComment() {
            const index = this.selectedPhoneId;
            this.client.client_phones[index].comment = this.commentPhoneText;
            this.closePhoneModal();
        },
        closePhoneModal() {
            this.modal_phone_comment = false;
            this.selectedPhoneId = null;
            this.commentPhoneText = '';
        },
        addNewPhone() {
            this.client.client_phones.push({
                "id": 0, "value": "", "comment": ""
            })
            this.$nextTick(() => {
                const index = this.client.client_phones.length - 1;
                const input = document.querySelector("#contact_number_" + index);
                this.client.client_phones[index]['countryIti'] = intlTelInput(input, {
                    separateDialCode: true,
                });
            });
        },
        setPreferredPhone(index) {
            this.client.client_phones.forEach((phone, i) => {
                phone.preferred = index === i ? 1 : 0;
            });
        },
        removeEmailAndPhone(id, type, index) {
            if (id) {
                let _vm = this;
                this.$dialog
                .confirm("Are you sure you want to delete this record?")
                .then(function () {
                        store.state.isLoaderShow = true;
                        _vm.axios
                            .get(`/delete-client-contact` + `/` + type + `/` + id)
                            .then(function () {
                                _vm.getDetail();
                                store.state.isLoaderShow = false;
                            })
                            .catch(function (error) {
                            });
                    })
                    .catch(function (error) {
                    });
            } else {
                this.client.client_phones.splice(index, 1);
            }
        },
    },

    mounted() {
        if (this.client.client_phones.length === 1) {
            this.setPreferredPhone(0);
        }
    }
}
</script>


<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-body {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}

.popover-body {
    background-color: #4e1ff94d !important;
    border-radius: 8px;
    padding: 1em;
    padding-left: 0px;
    font-weight: 600;
}
</style>