import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import emails from "./client_emails.vue";
import phones from "./client_phones.vue";
import store from "@/store/index.js";

export default {
  data: () => ({
    client: {
      old_client_id: "",
      id: 0,
      first_name: "",
      last_name: "",
      company: "",
      client_number: "",
      client_emails: [{ id: 0, value: "", comment: "" }],
      client_phones: [{ id: 0, value: "", comment: "" }],
    },
    clientDate: {},
    merge: {
      id: "",
    },
    showClientMerge: false,
    companyList: [],
    loading: false,
    error: "",
    backUrl: "/clients",
  }),

  components: {
    emails,
    phones,
  },

  methods: {
    resetForm() {
      this.$refs.observer.reset();
      this.client = {
        id: 0,
        first_name: "",
        last_name: "",
        company: "",
        client_number: "",
      };
    },
    validateClient() {
      // Check if the client ID is greater than 0
      this.client.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    update() {
      let _vm = this;
      for (let index = 0; index < _vm.client.client_phones.length; index++) {
        _vm.client.client_phones[index]["prefix"] =
          "+" +
          _vm.client.client_phones[index]["countryIti"].getSelectedCountryData()
            .dialCode;
      }
      this.axios
        .post("/client/" + this.client.id, {
          ...this.client,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.client = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    add() {
      let _vm = this;
      for (let index = 0; index < _vm.client.client_phones.length; index++) {
        _vm.client.client_phones[index]["prefix"] =
          "+" +
          _vm.client.client_phones[index]["countryIti"].getSelectedCountryData()
            .dialCode;
      }
      this.axios
        .post("/client", _vm.client)
        .then(function (response) {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },

    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/client/" + this.$route.params.id)
        .then(function (response) {
          _vm.client = response.data.data;
          if (_vm.client.client_emails == 0) {
            _vm.client.client_emails = [{ id: 0, value: "" }];
          }
          if (_vm.client.client_phones.length === 0) {
            _vm.client.client_phones = [{ id: 0 }];
          }
          _vm.$nextTick(() => {
            for (
              let index = 0;
              index < _vm.client.client_phones.length;
              index++
            ) {
              const input = document.querySelector("#contact_number_" + index);
              _vm.client.client_phones[index]["countryIti"] = intlTelInput(
                input,
                {
                  separateDialCode: true,
                }
              );
              _vm.client.client_phones[index]["countryIti"].setNumber(
                _vm.client.client_phones[index]["prefix"] +
                  _vm.client.client_phones[index]["value"]
              );
            }
          });
        })
        .catch(function () {
          // Error handling
        });
    },

    getCompany() {
      let _vm = this;
      this.axios
        .get("company")
        .then(function (response) {
          _vm.companyList = response.data.data.data;
        })
        .catch(function () {});
    },
    mergeClient(id) {
      let _vm = this;
      store.state.isLoaderShow = true;
      const client_merge = this.merge.id;
      this.axios
        .get(`client-confirmation/` + id + `/` + client_merge)
        .then(function (response) {
          _vm.clientDate = JSON.parse(atob(response.data.data));
          _vm.showClientMerge = true;
          store.state.isLoaderShow = false;
        })
        .catch(function () {});
    },
    afterMergeClientDelete(mainClient_id) {
      let _vm = this;
      store.state.isLoaderShow = true;
      const client_merge = this.clientDate.id;
      this.axios
        .get(`client-merge/` + mainClient_id + `/` + client_merge)
        .then(function (response) {
          _vm.getDetail();
          _vm.showClientMerge = false;
          _vm.merge.id = "";
          store.state.isLoaderShow = false;
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.$store.state.clientId = 0;
    setTimeout(() => {
      if (this.$route.params.id > 0) {
        this.disabled = this.$route.params.id > 0;
        this.getDetail();
      } else {
        for (let index = 0; index < this.client.client_phones.length; index++) {
          const input = document.querySelector("#contact_number_" + index);
          this.client.client_phones[index]["countryIti"] = intlTelInput(input, {
            separateDialCode: true,
          });
        }
      }
    }, 1000);
    this.getCompany();

    const style = document.createElement("style");
    style.innerHTML = `
      @media (max-width: 767px) {
        .iq-card .iq-card-header  {
          width: 683px !important;
          overflow-y:hidden;
        }
      }
      @media (max-width: 575px) {
        .iq-card .iq-card-header  {
          width: 500px !important;
          overflow-y:hidden;
        }
      }
      @media (max-width: 320px) {
        .iq-card .iq-card-header  {
          width: 400px !important;
          overflow-y:hidden;
        }
      }
    `;
    document.head.appendChild(style);
  },
};
