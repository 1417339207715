<template>
  <div>
    <label class="form-control-label">Email(s)</label>
    <div v-if="client?.client_emails">
      <div class="form-group d-inline-block w-100" v-for="(email, index) in client.client_emails"
        :key="'email' + index">
        <validation-provider name="Email" rules="required" v-slot="{ errors }">
          <div class="row">
            <div class="col-md-8 d-flex">
              <div class="custom-control custom-radio mt-2 mr-1">
                <input type="radio" :id="'email_preferred_' + index" name="customRadio1" :checked="email.preferred == 1"
                  class="custom-control-input" @change="setPreferredEmail(index)">
                <label class="custom-control-label" :for="'email_preferred_' + index"></label>
              </div>
              <input type="text" :id="'email' + index" :name="'email' + index" :class="{ 'is-invalid': errors[0] }"
                v-model="email.value" @blur="searchClientByEmail($event.target.value, index)"
                :disabled="!hasClientUpdatePermission" placeholder="Enter Email..."
                class="au-input au-input--full form-control" />
            </div>
            <div class="col-md-4 text-end mt-2">
              <i class="fa-solid fa-comment fa-xl mr-2" :class="email.comment ? 'text-dark' : 'text-muted'"
                data-toggle="tooltip" data-placement="top" :id="'popover1-btn2_' + index" v-b-popover.hover.left
                @click="openEmailModal(index)" :target="'popover1-btn2_' + index" style="cursor: pointer;"></i>
              <b-popover variant="warning" :target="'popover1-btn2_' + index" triggers="hover" placement="left"
                class=" " v-if="email.comment">
                <div class="iq-card-body p-0 ">
                  <ul style="list-style:disc;margin-bottom: 0px;margin-top: 0px;">
                    <li><span>{{ email.comment }}</span></li>
                  </ul>
                </div>
              </b-popover>
              <i class="fa-solid fa-plus fa-xl text-primary mr-2" v-if="client.client_emails.length == index + 1"
                variant="primary" type="button" value="Add Another Email"
                @click="client.client_emails.push({ value: '' })"></i>

              <i v-if="client?.client_emails.length > 1" class="fa-solid fa-xmark fa-xl text-danger mr-2"
                variant="danger" type="button" value="Remove" @click="removeEmailAndPhone(email.id, 'email', index)">
              </i>
            </div>
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </div>
        </validation-provider>
      </div>
    </div>
    <transition name="modal">
      <div class="modal-mask" v-if="modal_email_comment">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content ">
              <ValidationObserver ref="observer">
                <form @submit.prevent="saveEmailComment">
                  <div class="modal-header">
                    <h5 class="modal-title">Email Comment</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" @click="closeEmailModal">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body hei pt-2">
                    <div class="row">
                      <textarea type="text" v-model="commentEmailText" class="form-control" id="emailComment_"
                        placeholder="Enter comment for Email" ref="emailCommentRefs" rows="4"></textarea>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn border-none btn-primary btn-sm ">Save</button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import store from "@/store/index.js";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

export default {
  name: "emails",
  data() {
    return {
      isEmailSearchRunning: false,
      modal_email_comment: false,
      commentEmailText: '',
    }
  },
  props: {
    client: {},
    getDetail: {
      type: Function,
      required: true,
    },
    add: {
      type: Function,
      required: true,
    },
    update: {
      type: Function,
      required: true,
    },
  },
  computed: {
    hasClientUpdatePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = 'client-update-contacts';
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    openEmailModal(index) {
      this.modal_email_comment = true;
      this.selectedEmailId = index;
    },
    saveEmailComment() {
      const index = this.selectedEmailId;
      this.client.client_emails[index].comment = this.commentEmailText;
      this.closeEmailModal();
    },
    closeEmailModal() {
      this.modal_email_comment = false;
      this.selectedEmailId = null;
      this.commentEmailText = '';
    },
    setPreferredEmail(index) {
      this.client.client_emails.forEach((email, i) => {
        email.preferred = index === i ? 1 : 0;
      });
    },
    removeEmailAndPhone(id, type, index) {
      if (id) {
        let _vm = this;
        this.$dialog
          .confirm("Are you sure you want to delete this record?")
          .then(function () {
            store.state.isLoaderShow = true;
            _vm.axios
              .get(`/delete-client-contact` + `/` + type + `/` + id)
              .then(function () {
                _vm.getDetail();
                store.state.isLoaderShow = false;
              })
              .catch(function (error) {
              });
          })
          .catch(function (error) {
          });
      } else {
        this.client.client_emails.splice(index, 1);
      }
    },
    searchClientByEmail(email, index) {
      let _vm = this;
      if (_vm.searchEmail == email) {
        return;
      }
      store.state.isLoaderShow = true;
      _vm.searchEmail = email;
      _vm.isEmailSearchRunning = true;
      _vm.axios
        .get("/search-client-by-email?email=" + email)
        .then(function (response) {
          let data = JSON.parse(atob(response.data.data));
          if (data.client && data.client.id > 0) {
            if (_vm.client.id == 0) {
              if (data.oldClient) {
                // _vm.client.id = data.client.id;
                store.state.isLoaderShow = false;
                _vm.client.old_client_id = data.client.id;
                _vm.client.first_name = data.client.first_name;
                _vm.client.middle_name = data.client.middle_name;
                _vm.client.last_name = data.client.last_name;
                _vm.client.company_id = data.client.company_id;

                let oldClientEmails = [];

                data.client.old_emails.forEach((e) => {
                  let emailObj = {
                    comment: null,
                    preferred: false,
                    value: e.email,
                  };
                  oldClientEmails.push(emailObj);
                })
                _vm.client.client_emails = oldClientEmails;

                let oldClientPhones = [];
                data.client.old_phones.forEach((p) => {
                  let phoneObj = {
                    comment: null,
                    preferred: false,
                    prefix: p.prefix,
                    value: p.phone,
                  };
                  oldClientPhones.push(phoneObj);
                })
                _vm.client.client_phones = oldClientPhones;

                _vm.$nextTick(() => {
                  for (let i = 0; i < _vm.client.client_phones.length; i++) {
                    const input = document.querySelector("#contact_number_" + i);
                    _vm.client.client_phones[i]["countryIti"] = intlTelInput(
                      input,
                      {
                        separateDialCode: true,
                      }
                    );
                    _vm.client.client_phones[i]["countryIti"].setNumber(
                      _vm.client.client_phones[i]["prefix"] +
                      _vm.client.client_phones[i]["value"]
                    );
                  }
                });

              } else {
                store.state.isLoaderShow = false;
                _vm.$router.push('/clients/update-client/' + data.client.id);
                _vm.getDetail();
              }
            } else {
              if (_vm.client.cwe) {
                if (data.oldClient) {
                  store.state.isLoaderShow = false;
                  _vm.client.old_client_id = data.client.id;
                  _vm.client.first_name = data.client.first_name;
                  _vm.client.middle_name = data.client.middle_name;
                  _vm.client.last_name = data.client.last_name;
                  _vm.client.company_id = data.client.company_id;

                  let oldClientEmails = [];

                  data.client.old_emails.forEach((e) => {
                    let emailObj = {
                      comment: null,
                      preferred: false,
                      value: e.email,
                    };
                    oldClientEmails.push(emailObj);
                  })
                  _vm.client.client_emails = oldClientEmails;

                  // let oldClientPhones = [];
                  data.client.old_phones.forEach((p) => {
                    let phoneObj = {
                      comment: null,
                      preferred: false,
                      prefix: p.prefix,
                      value: p.phone,
                    };
                    // oldClientPhones.push(phoneObj);
                    _vm.client.client_phones.push(phoneObj);
                    _vm.$nextTick(() => {
                      for (let i = 0; i < _vm.client.client_phones.length; i++) {
                        const input = document.querySelector("#contact_number_" + i);
                        _vm.client.client_phones[i]["countryIti"] = intlTelInput(
                          input,
                          {
                            separateDialCode: true,
                          }
                        );
                        _vm.client.client_phones[i]["countryIti"].setNumber(
                          _vm.client.client_phones[i]["prefix"] +
                          _vm.client.client_phones[i]["value"]
                        );
                      }
                    });
                  })
                } else {
                  _vm.axios
                    .get(`client-confirmation/` + data.client.id + `/` + _vm.client.id)
                    .then(function (response) {
                      _vm.clientData = JSON.parse(atob(response.data.data));
                      store.state.isLoaderShow = false;
                      _vm.$dialog
                        .confirm(`After merging '${_vm.clientData.first_name + ' ' + _vm.clientData.last_name} (${_vm.clientData.id}) with ${data.client.first_name + ' ' + data.client.last_name} (${data.client.id})' the record for ${_vm.clientData.first_name + ' ' + _vm.clientData.last_name} (${_vm.clientData.id}) will be permanently deleted.`)
                        .then(function () {
                          store.state.isLoaderShow = true;
                          _vm.axios
                            .get(`client-merge/` + data.client.id + `/` + _vm.client.id)
                            .then(function () {
                              store.state.isLoaderShow = false;
                              _vm.$router.push('/clients/update-client/' + data.client.id);
                              _vm.getDetail();
                            })
                            .catch(function (error) {
                            });
                        })
                        .catch(function (error) {
                          _vm.client.client_emails[index].value = "";
                        });
                    })
                    .catch(function () { });
                }
              } else {
                _vm.$dialog
                  .alert("Email already used in other client please use another email.")
                  .then(function () {
                    if (_vm.$route.params.id > 0) {
                      if (_vm.$route.params.id == data.id) {
                        _vm.client.client_emails[index].value = "";
                      } else if (_vm.$route.params.id != data.id) {
                        _vm.client.client_emails[index].value = "";
                      } else {
                        _vm.client.client_emails[index].value = _vm.client.client_emails[index].value;
                      }
                    } else {
                      _vm.client.client_emails[index].value = "";
                    }
                    _vm.searchEmail = "";
                    _vm.isEmailSearchRunning = false;
                  })
                  .catch(function (error) {
                    _vm.isEmailSearchRunning = false;
                  });
              }
            }
          }
          _vm.isEmailSearchRunning = false;
        })
        .catch(function (error) {
          _vm.isEmailSearchRunning = false;
        });
    },
  },
  mounted() {
    if (this.client.client_emails.length === 1) {
      this.setPreferredEmail(0);
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0) {
      this.searchClientByEmail(params.email);
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.popover-body {
  background-color: #4e1ff94d !important;
  border-radius: 8px;
  padding: 1em;
  padding-left: 0px;
  font-weight: 600;
}
</style>
